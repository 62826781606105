import { type AttendanceConfirmedRecord } from "@/domain/features/company/v2/entities/attendance_confirmed_record/attendance_confirmed_record"
import { type WorkDayType } from "@/domain/features/company/v2/entities/work_style/fixed_work_style_settings"

export type AttendanceType = (typeof AttendanceType.values)[number]

export namespace AttendanceType {
  export const values = [
    /** 勤務日 */
    "workDay",

    /** 法定休日 */
    "legalHoliday",

    /** 所定休日 */
    "companySpecificHoliday",

    /** 欠勤 */
    "absence",

    /** 有休 */
    "paidLeave",

    /** 休日出勤 */
    "holidayWork",

    /** 代休 | 振替休日 */
    "alternativeHoliday",
  ] as const

  export function fromWorkDayType(workDayType: WorkDayType): AttendanceType {
    switch (workDayType) {
      case "workDay":
        return "workDay"
      case "legalHoliday":
        return "legalHoliday"
      case "companySpecificHoliday":
        return "companySpecificHoliday"
    }
  }
}

export type AttendanceSelectionType =
  (typeof AttendanceSelectionType.values)[number]

export namespace AttendanceSelectionType {
  export const values = [
    /** 勤務日 */
    "workDay",

    /** 所定休日 */
    "companySpecificHoliday",

    /** 法定休日 */
    "legalHoliday",

    /** 欠勤 */
    "absence",

    /** 有休 */
    "paidLeave",

    /** 休日出勤 */
    "holidayWork",

    /** 振替休日 */
    "substitutedHoliday",

    /** 代休 */
    "compensatoryHoliday",
  ] as const

  export function format(type: AttendanceSelectionType) {
    switch (type) {
      case "workDay":
        return "出勤"
      case "legalHoliday":
        return "法定休日"
      case "companySpecificHoliday":
        return "所定休日"
      case "absence":
        return "欠勤"
      case "paidLeave":
        return "有休"
      case "holidayWork":
        return "休日出勤"
      case "substitutedHoliday":
        return "振替休日"
      case "compensatoryHoliday":
        return "代休"
    }
  }

  export function fromAttendanceConfirmedRecord(
    attendanceConfirmedRecord: AttendanceConfirmedRecord | undefined
  ): AttendanceSelectionType | undefined {
    if (attendanceConfirmedRecord?.attendanceType == null) {
      return undefined
    }

    switch (attendanceConfirmedRecord.attendanceType) {
      case "workDay":
      case "legalHoliday":
      case "companySpecificHoliday":
      case "absence":
      case "paidLeave":
      case "holidayWork":
        return attendanceConfirmedRecord.attendanceType
      case "alternativeHoliday":
        if (
          attendanceConfirmedRecord.alternativeHolidayMetadata
            ?.alternativeHolidayType == null
        ) {
          return undefined
        }

        switch (
          attendanceConfirmedRecord.alternativeHolidayMetadata
            ?.alternativeHolidayType
        ) {
          case "substituted":
            return "substitutedHoliday"
          case "compensatory":
            return "compensatoryHoliday"
        }
    }
  }
}
