import { getFirebaseAuthInfra } from "@/infrastructure/firebase_auth/client"
import { FirebaseFunctionsInfra } from "@/infrastructure/firebase_functions/core/functions"
import { getFirebaseApp } from "@/infrastructure/firebase/client"
import { CLOUD_FUNCTIONS_REGION } from "@/infrastructure/firebase/config"
import { getFunctions } from "firebase/functions"

export function getFirebaseFunctionsInfra(): FirebaseFunctionsInfra {
  const firebaseApp = getFirebaseApp()
  const functions = getFunctions(firebaseApp)

  functions.region = CLOUD_FUNCTIONS_REGION

  // 認証状態は初期化する必要があるので、実行しておく
  getFirebaseAuthInfra()

  return new FirebaseFunctionsInfra(functions)
}
