import * as dateFns from "date-fns"

/**
 * 祝日一覧の結果
 */
export class NationalHolidayList {
  constructor(private readonly dateList: Date[]) {}

  static get empty() {
    return new NationalHolidayList([])
  }

  contains(date: Date): boolean {
    return this.dateList.some((value) => {
      return dateFns.isSameDay(date, value)
    })
  }

  // 単にリストを返す。
  //
  // プロダクトでは contains しか使う想定がないので、テスト用に用意している。
  // なので、ファイルサイズ削減の関係で DeepCopy にするためのライブラリはインポートしておらず、最低限の対策しかしていない。
  list(): Date[] {
    return Array.from(
      this.dateList.map((e) => {
        return new Date(e)
      })
    )
  }
}
