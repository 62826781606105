import { formatLocalIsoString } from "@/common/utils/local_iso_string"
import { type LatLng } from "@/domain/core/entities/lat_lng"
import {
  AlternativeHolidayType,
  HolidayType,
  type AttendanceApplicationType,
} from "@/domain/features/company/v2/entities/attendance_application/attendance_application"
import { AttendanceType } from "@/domain/features/company/v2/entities/attendance_type/attendance_type"
import {
  type ExportCsvRequest,
  type ExportCsvResponse,
} from "@/domain/features/company/v2/entities/data-export/csv-export-io"
import { NationalHolidayList } from "@/domain/features/company/v2/entities/national_holiday/national_holiday_list"
import { HalfDayPaidLeavePeriod } from "@/domain/features/company/v2/entities/paid_leave_unit/paid_leave_unit"
import { format } from "date-fns"
import { httpsCallable, type Functions } from "firebase/functions"
import { z } from "zod"

/**
 * Firebase Functions の呼び出しを行うクラス
 */
export class FirebaseFunctionsInfra {
  #functions: Functions

  constructor(functions: Functions) {
    this.#functions = functions
  }

  async otpInitialize(params: {
    type: "token" | "updateEmail"
    email: string
  }) {
    const response = await httpsCallable(
      this.#functions,
      "session-v2-callable-otpInitialize"
    )(params)

    return response.data as { documentId: string }
  }

  async otpVerify(params: { documentId: string; otp: string }) {
    const response = await httpsCallable(
      this.#functions,
      "session-v2-callable-otpVerify"
    )(params)

    return response.data as { token: string }
  }

  async checkIn({
    location,
    companyId,
  }: {
    location: LatLng
    companyId: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-checkIn"
    )({
      location,
      companyId,
    })
  }

  async checkOut({
    location,
    companyId,
  }: {
    location: LatLng
    companyId: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-checkOut"
    )({
      location,
      companyId,
    })
  }

  async attendanceApplicationApply(
    params: AttendanceApplicationApply.params
  ): Promise<{ applicationId: string }> {
    const attendanceApplicationApplyResponse = await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceApplicationApply"
    )(params)

    return attendanceApplicationApplyResponse.data as { applicationId: string }
  }

  async attendanceApplicationApprove({
    companyId,
    appliedBy,
    applicationId,
  }: {
    companyId: string
    appliedBy: string
    applicationId: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceApplicationApprove"
    )({
      companyId,
      appliedBy,
      applicationId,
    })
  }

  async attendanceApplicationBulkStampApprove(
    params: AttendanceApplicationBulkStampApprove.params
  ) {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceApplicationBulkStampApprove"
    )(AttendanceApplicationBulkStampApprove.schema.parse(params))
  }

  async attendanceApplicationReject({
    companyId,
    appliedBy,
    applicationId,
    reason,
  }: {
    companyId: string
    appliedBy: string
    applicationId: string
    reason: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceApplicationReject"
    )({
      companyId,
      appliedBy,
      applicationId,
      reason,
    })
  }

  async attendanceConfirmedRecordDelete({
    companyId,
    userId,
    targetDate: dateStr,
  }: {
    companyId: string
    userId: string
    targetDate: Date
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceConfirmedRecordDelete"
    )({
      companyId,
      userId,
      targetDate: format(dateStr, "yyyy-MM-dd"),
    })
  }

  async attendanceConfirmedRecordOverwrite(
    params: AttendanceConfirmedRecordOverwrite.params
  ): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-attendanceConfirmedRecordOverwrite"
    )(AttendanceConfirmedRecordOverwrite.schema.parse(params))
  }

  async nationalHoliday(params: {
    year: number
  }): Promise<NationalHolidayList> {
    const response = await httpsCallable(
      this.#functions,
      "company-v2-callable-nationalHoliday"
    )(params)

    let array: string[] = []

    if (
      typeof response.data === "object" &&
      response.data !== null &&
      "list" in response.data
    ) {
      array = response.data["list"] as string[]
    }

    return new NationalHolidayList(array.map((source) => new Date(source)))
  }

  async registerCompany({
    companyName,
    subscriptionPlanId,
    stripe: { token, idempotencyKey, enableTrial, couponId },
  }: {
    companyName: string
    subscriptionPlanId: string
    stripe: {
      token: string
      idempotencyKey: string
      enableTrial: boolean
      couponId: string
    }
  }): Promise<{ companyId: string }> {
    const registerCompanyResponse = await httpsCallable(
      this.#functions,
      "company-v2-callable-registerCompany"
    )({
      companyName,
      subscriptionPlanId,
      stripe: {
        token,
        idempotencyKey,
        enableTrial,
        couponId,
      },
    })

    return registerCompanyResponse.data as { companyId: string }
  }

  async unsubscribe({
    companyId,
    reason,
    otherReason,
    idempotencyKey,
  }: {
    companyId: string
    reason: string
    otherReason: string
    idempotencyKey: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-unsubscribe"
    )({
      companyId,
      reason,
      otherReason,
      idempotencyKey,
    })
  }

  async workerRemove({
    companyId,
    userId,
  }: {
    companyId: string
    userId: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-workerRemove"
    )({
      companyId,
      userId,
    })
  }

  async workerRegister(params: {
    email: string
    companyId: string
    workerNumber?: string
    firstName: string
    lastName: string
    firstNameRuby?: string
    lastNameRuby?: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-workerRegister"
    )(params)
  }

  async getWorkerEmail({
    userId,
    companyId,
  }: {
    userId: string
    companyId: string
  }): Promise<{ email: string }> {
    const getWorkerEmailResponse = await httpsCallable<
      { userId: string; companyId: string },
      { email: string }
    >(
      this.#functions,
      "company-v2-callable-getWorkerEmail"
    )({
      userId,
      companyId,
    })

    return getWorkerEmailResponse.data
  }

  async joiningCompanyApplicationApprove({
    companyId,
    userId,
    applicationId,
  }: {
    userId: string
    companyId: string
    applicationId: string
  }): Promise<void> {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-joiningCompanyApplicationApprove"
    )({
      userId,
      companyId,
      applicationId,
    })
  }

  async sendSharedAttendancePassword({
    companyId,
    userIds,
  }: {
    companyId: string
    userIds: string[]
  }) {
    await httpsCallable(
      this.#functions,
      "company-v2-callable-sendSharedAttendancePassword"
    )({
      companyId,
      userIds,
    })
  }

  async deleteUser() {
    await httpsCallable(this.#functions, "deleteUser-v2-callable-deleteUser")()
  }

  async exportCsvFile(params: ExportCsvRequest): Promise<ExportCsvResponse> {
    const response = await httpsCallable(
      this.#functions,
      "company-v2-callable-exportFile"
    )(params)

    return response.data as ExportCsvResponse
  }
}

namespace z_helper {
  export const dateOnlyString = () =>
    z.date().transform((v) => format(v, "yyyy-MM-dd"))
  export const dateLocalIsoString = () =>
    z.date().transform((v) => formatLocalIsoString(v))
  export const location = () =>
    z.object({
      latitude: z.number(),
      longitude: z.number(),
    })
  export const attendances = () =>
    z.array(
      z.object({
        checkedInAt: dateLocalIsoString(),
        checkedOutAt: dateLocalIsoString(),
        checkInLocation: location().nullish(),
        checkOutLocation: location().nullish(),
        breaktimeMinutes: z.number().nonnegative().int(),
        overtimeMinutes: z.number().nonnegative().int(),
      })
    )
  export const paidLeaveDetails = () =>
    z.union([
      z.object({
        unit: z.literal("allDay"),
      }),
      z.object({
        unit: z.literal("halfDay"),
        period: z.enum(HalfDayPaidLeavePeriod.values),
      }),
      z.object({
        unit: z.literal("hours"),
        hours: z.number().int().positive(),
      }),
    ])
}

export namespace AttendanceApplicationApply {
  export const attendancesSchema = z_helper.attendances()

  const detailsParamsSchema = z.union([
    z.object({
      type: z.literal("absence"),
    }),
    z.intersection(
      z.object({
        type: z.literal("paidLeave"),
        attendances: attendancesSchema.nullish(),
      }),
      z_helper.paidLeaveDetails()
    ),
    z.object({
      type: z_application_type("editAttendance"),
      attendances: attendancesSchema,
    }),
    z.object({
      type: z_application_type("overtime"),
      attendances: attendancesSchema,
    }),
    z.object({
      type: z_application_type("holidayWork"),
      holidayType: z.enum(HolidayType.values),
      attendances: attendancesSchema,
      alternativeHoliday: z
        .nullable(
          z.object({
            date: z_helper.dateOnlyString(),
            type: z.enum(AlternativeHolidayType.values),
          })
        )
        .transform((v) => v ?? undefined),
    }),
  ])

  export type detailsParams = (typeof detailsParamsSchema)["_input"]
  export const paramsSchema = z.intersection(
    z.object({
      companyId: z.string().min(1),
      targetDate: z_helper.dateOnlyString(),
      attendanceMemo: z.string().nullish(),
      applicationComment: z.string().nullish(),
    }),
    detailsParamsSchema
  )

  export type params = (typeof paramsSchema)["_output"]

  function z_application_type<T extends AttendanceApplicationType>(type: T) {
    return z.literal(type)
  }
}

export namespace AttendanceApplicationBulkStampApprove {
  export const schema = z.object({
    companyId: z.string().min(1),
    applications: z.array(
      z.object({
        appliedBy: z.string().min(1),
        applicationId: z.string().min(1),
      })
    ),
  })

  export type params = (typeof schema)["_input"]
}

export namespace AttendanceConfirmedRecordOverwrite {
  export const schema = z.object({
    companyId: z.string().min(1),
    userId: z.string().min(1),
    targetDate: z_helper.dateOnlyString(),
    attendanceType: z.enum(AttendanceType.values),
    alternativeHolidayDetails: z
      .object({
        holidayType: z.enum(HolidayType.values),
        alternativeHolidayType: z.enum(AlternativeHolidayType.values),
        workDate: z_helper.dateOnlyString(),
      })
      .nullish()
      .transform((v) => v ?? undefined),
    holidayWorkDetails: z
      .object({
        holidayType: z.enum(HolidayType.values),
        alternativeHoliday: z
          .object({
            date: z_helper.dateOnlyString(),
            type: z.enum(AlternativeHolidayType.values),
          })
          .nullish()
          .transform((v) => v ?? undefined),
      })
      .nullish()
      .transform((v) => v ?? undefined),
    paidLeaveDetails: z_helper
      .paidLeaveDetails()
      .nullish()
      .transform((v) => v ?? undefined),
    attendances: z_helper
      .attendances()
      .nullish()
      .transform((v) => v ?? undefined),
    memo: z
      .string()
      .nullish()
      .transform((v) => v ?? undefined),
  })

  export type params = (typeof schema)["_input"]
}
