export type PaidLeaveUnit = (typeof PaidLeaveUnit.values)[number]

export namespace PaidLeaveUnit {
  export const values = ["allDay", "halfDay", "hours"] as const

  export function format(value: PaidLeaveUnit) {
    switch (value) {
      case "allDay":
        return "1日"
      case "halfDay":
        return "半日"
      case "hours":
        return "時間"
    }
  }
}

export type HalfDayPaidLeavePeriod =
  (typeof HalfDayPaidLeavePeriod.values)[number]
export namespace HalfDayPaidLeavePeriod {
  export const values = ["am", "pm"] as const

  export function format(value: HalfDayPaidLeavePeriod) {
    switch (value) {
      case "am":
        return "午前"
      case "pm":
        return "午後"
    }
  }
}
